@charset "utf-8";

// Define defaults for each variable.

$base-font-family: "Helvetica Neue", Helvetica, Arial, sans-serif !default;
$base-font-size:   16px !default;
$base-font-weight: 400 !default;
$small-font-size:  $base-font-size * 0.875 !default;
$base-line-height: 1.5 !default;

$spacing-unit:     30px !default;

$text-color:       #111 !default;
$background-color: #fdfdfd !default;
$brand-color:      #2a7ae2 !default;

$grey-color:       #828282 !default;
$grey-color-light: lighten($grey-color, 40%) !default;
$grey-color-dark:  darken($grey-color, 25%) !default;

// Width of the content area
$content-width:    800px !default;

$on-palm:          600px !default;
$on-laptop:        800px !default;

// Use media queries like this:
// @include media-query($on-palm) {
//   .wrapper {
//     padding-right: calc($spacing-unit / 2);
//     padding-left: calc($spacing-unit / 2);
//   }
// }
@mixin media-query($device) {
  @media screen and (max-width: $device) {
    @content;
  }
}

@mixin relative-font-size($ratio) {
  font-size: $base-font-size * $ratio;
}

// Customization
@import url(//fonts.googleapis.com/css?family=Open+Sans:400,300,300italic,400italic,600,600italic);

$base-font-family: "Open Sans", $base-font-family;

$baseurl: "";
$color1: #705b35;
$color2: #c7b07b;
$color3: #e8d9ac;
$color4: #fff6d9;
$color5: #570026;
$link-color: #2a7ae2;
$header-background-color: #e5dab7;
$text-color: #333;
$content-width:    1140px;
$content-min-width:    380px;
$on-palm:          700px;
$on-laptop:        1050px;

@mixin ul-columns {
  display: flex;
  margin: 0;
  li {
    padding: 5px;
    margin: 0;
    display: block;
    flex: 1;
    h3 {
      margin-bottom: 10px;
    }
  }
}

// Import partials
@import
  "base",
  "layout",
  "syntax-highlighting",
  "buttons",
  "icomoon",
  "content",
  "carousel"
;
