.carousel {
  .screenshots {
    z-index: 0;
    background-color: #fff;

    & > div {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      visibility: hidden;
      transition: opacity 0.5s;

      &:first-child {
        position: static;
      }
    }

    span {
      position: absolute;
      display: block;
      width: 100%;
      bottom: 0;
      height: 50px;
      box-shadow: 0 0 20px 20px rgba(255, 255, 255, 0.8);
      background-color: rgba(255, 255, 255, 0.8);

      font-weight: bold;
      text-align: center;
      color: $color5;
    }
  }

  .dots {
    text-align: center;
    display: block;
    position: absolute;
    bottom: 0px;
    width: 100%;
    z-index: 1;
    span {
      cursor: pointer;
      height: 13px;
      width: 13px;
      margin: 0 2px;
      background-color: rgba(0, 0, 0, 0.2);
      border-radius: 50%;
      display: inline-block;
      transition: background-color 0.4s ease;
      &.active, &:hover {
        background-color: $color5;
      }
    }
  }

  /* Next & previous buttons */
  .prev, .next {
    z-index: 1;
    cursor: pointer;
    position: absolute;
    top: 50%;
    width: auto;
    margin-top: -29px;
    padding: 20px 16px;
    color: rgba(0, 0, 0, 0.2);
    font-weight: bold;
    font-size: 18px;
    line-height: 18px;
    transition: 0.4s ease;
    border-radius: 0 5px 5px 0;

    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  /* Position the "next button" to the right */
  .next {
    right: 0;
    border-radius: 5px 0 0 5px;
  }

  /* On hover, add a black background color with a little bit see-through */
  .prev:hover, .next:hover {
    background-color: rgba(0,0,0,0.3);
    color: white;
  }
}
