/**
 * Site header
 */
.site-header {
  border-top: 1px solid $color2;
  border-bottom: 1px solid $color3;
  min-height: $spacing-unit * 1.865;

  // Positioning context for the mobile navigation icon
  position: relative;

  background-image: url(../images/header-background.svg);
  background-color: $header-background-color;
  min-height: 56px;
  box-shadow: 0 -10px 10px -10px rgba(0, 0, 0, 0.2) inset;

  $shadow-spread: 3px;
  text-shadow: 0-$shadow-spread 0 $shadow-spread $header-background-color,
    $shadow-spread 0 $shadow-spread $header-background-color,
    0 0-$shadow-spread $shadow-spread $header-background-color,
    0 $shadow-spread $shadow-spread $header-background-color;
}

.site-title {
  @include relative-font-size(1.625);
  font-weight: 300;
  line-height: $base-line-height * $base-font-size * 2.25;
  letter-spacing: -1px;
  margin: 10px 0;
  display: inline-block;

  &,
  &:visited {
    color: $grey-color-dark;
  }

  img {
    filter: drop-shadow(0px 0px 3px $color4);
  }
}

.site-nav {
  float: right;
  line-height: $base-line-height * $base-font-size * 2.25;

  text-transform: uppercase;
  font-weight: bold;

  .nav-trigger {
    display: none;
  }

  .menu-icon {
    display: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .page-link {
    color: $text-color;
    &:hover {
        color: $color5;
    }
    line-height: $base-line-height;

    // Gaps between nav items, but not on the last one
    &:not(:last-child) {
      margin-right: 20px;
    }
  }

  @include media-query($on-palm) {
    position: absolute;
    top: 9px;
    right: calc($spacing-unit / 2);
    background-color: $background-color;
    border: 1px solid $grey-color-light;
    border-radius: 5px;
    text-align: right;
    text-shadow: none;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    z-index: 100;
    direction: rtl;

    label[for="nav-trigger"] {
      display: block;
      float: right;
      width: 36px;
      height: 36px;
      z-index: 2;
      cursor: pointer;
    }

    .menu-icon {
      display: block;
      float: right;
      width: 36px;
      height: 26px;
      line-height: 0;
      padding-top: 10px;
      text-align: center;

      > svg path {
        fill: $grey-color-dark;
      }
    }

    input ~ .trigger {
      clear: both;
      display: block;
      transition: max-width 0.05s ease-in, max-height 0.1s ease-in;
      max-width: 36px;
      max-height: 0px;
      overflow: hidden;
    }

    input:checked ~ .trigger {
      padding-bottom: 5px;
      max-width: 140px;
      max-height: 100px;
    }

    .page-link {
      display: block;
      padding: 5px 10px;

      &:not(:last-child) {
        margin-right: 0;
      }
      margin-left: 20px;
    }
  }
}



/**
 * Site footer
 */
.site-footer {
  border-top: 1px dashed $grey-color-light;
  padding: calc($spacing-unit / 2) 0;
  background-image: url(../images/footer-background.png);
  color: $color3;
  @include relative-font-size(0.875);
  a {
    color: $color3;
    &:hover {
      color: $color4;
    }
  }

  .footer-nav {
    font-weight: bold;
    float: right;
    li {
      margin-left: 10px;
      width: 120px;
      display: inline;
    }
  }

  .copyright {
    @include relative-font-size(0.75);
    text-align: left;
    margin-top: $spacing-unit;
    vertical-align: baseline;
    @extend %clearfix;
    clear: both;
  }

  .social-media {
    float: right;
    margin-top: -12px;

    svg {
      display: inline-block;
      width: 32px;
      height: 32px;
      path {
        fill: $color3;
      }
    }
  }
}

/*
.footer-heading {
  @include relative-font-size(1.125);
  margin-bottom: calc($spacing-unit / 2);
}

.contact-list,
.social-media-list {
  list-style: none;
  margin-left: 0;
}

.footer-col-wrapper {
  @include relative-font-size(0.9375);
  color: $grey-color;
  margin-left: -calc($spacing-unit / 2);
  @extend %clearfix;
}

.footer-col {
  float: left;
  margin-bottom: calc($spacing-unit / 2);
  padding-left: calc($spacing-unit / 2);
}

.footer-col-1 {
  width: -webkit-calc(35% - (#{$spacing-unit} / 2));
  width:         calc(35% - (#{$spacing-unit} / 2));
}

.footer-col-2 {
  width: -webkit-calc(20% - (#{$spacing-unit} / 2));
  width:         calc(20% - (#{$spacing-unit} / 2));
}

.footer-col-3 {
  width: -webkit-calc(45% - (#{$spacing-unit} / 2));
  width:         calc(45% - (#{$spacing-unit} / 2));
}

@include media-query($on-laptop) {
  .footer-col-1,
  .footer-col-2 {
    width: -webkit-calc(50% - (#{$spacing-unit} / 2));
    width:         calc(50% - (#{$spacing-unit} / 2));
  }

  .footer-col-3 {
    width: -webkit-calc(100% - (#{$spacing-unit} / 2));
    width:         calc(100% - (#{$spacing-unit} / 2));
  }
}

@include media-query($on-palm) {
  .footer-col {
    float: none;
    width: -webkit-calc(100% - (#{$spacing-unit} / 2));
    width:         calc(100% - (#{$spacing-unit} / 2));
  }
}
*/


/**
 * Page content
 */
.page-content {
  padding: $spacing-unit 0;
  background-image: url(../images/content-background.png);

  h2 {
    @include relative-font-size(1.75);
    font-weight: 300;

    @include media-query($on-laptop) {
      @include relative-font-size(1.75 * 0.9);
    }
  }

  h3 {
    @include relative-font-size(1.375);
    font-weight: bold;
    @include media-query($on-laptop) {
      @include relative-font-size(1.375 * 0.9);
    }
  }

  h4 {
    @include relative-font-size(1.1);
    font-weight: bold;
    @include media-query($on-laptop) {
      @include relative-font-size(1.1);
    }
  }
  h1, h2, h3, h4 {
    color: $color5;
    a {
      color: $color5;
    }
  }
}

.page-heading {
  @include relative-font-size(1.25);
}

.post-list {
  margin-left: 0;
  list-style: none;

  > li {
    margin-bottom: $spacing-unit;
  }
}

.post-meta {
  font-size: $small-font-size;
  color: $grey-color;
  span[itemprop=author] {
    font-weight: bold;
  }
  time[itemprop=datePublished] {
    font-weight: bold;
  }
}

.post-link {
  display: block;
  @include relative-font-size(1.5);
}



/**
 * Posts
 */
.post-header {
  margin-bottom: $spacing-unit;
}

.post-title {
  @include relative-font-size(2.25);
  font-weight: 300;
  letter-spacing: -1px;
  line-height: 1;

  @include media-query($on-laptop) {
    @include relative-font-size(2.25 * 0.9);
  }
}

.post-content {
  margin-bottom: $spacing-unit;

  pre.highlight {
    @include relative-font-size(0.9375);
    background-color: $color4;
    padding: 10px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2) inset;
    border-radius: 5px;
    overflow-x: auto;
  }
}
