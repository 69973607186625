$icomoon-font-path: "#{$baseurl}/fonts" !default;

$icon-sim: "\e900";
$icon-server: "\e901";
$icon-puzzle: "\e902";


@font-face {
  font-family: 'icomoon';
  src:  url('#{$icomoon-font-path}/icomoon.eot?lconer');
  src:  url('#{$icomoon-font-path}/icomoon.eot?lconer#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/icomoon.ttf?lconer') format('truetype'),
    url('#{$icomoon-font-path}/icomoon.woff?lconer') format('woff'),
    url('#{$icomoon-font-path}/icomoon.svg?lconer#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

@mixin icon($icon) {
  &:before {
		/* use !important to prevent issues with browser extensions that change fonts */
	  font-family: 'icomoon' !important;
	  speak: none;
	  font-style: normal;
	  font-weight: normal;
	  font-variant: normal;
	  text-transform: none;
	  line-height: 1;

	  /* Better Font Rendering */
	  -webkit-font-smoothing: antialiased;
	  -moz-osx-font-smoothing: grayscale;

    margin-right: 0.4em;

		content: $icon;
  }
}

.icon-sim {
	@include icon($icon-sim);
}

.icon-server {
	@include icon($icon-server);
}

.icon-puzzle {
	@include icon($icon-puzzle);
}
