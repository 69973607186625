.site-header {
  .screenshot-wrapper {
    clear: both;
    overflow: hidden;
    @include media-query($on-laptop) {
      overflow: visible;
    }
  }

  #screenshot {
    text-shadow: none;
    position: relative;
    overflow: hidden;
    float: right;
    margin: 10px 10px 0 10px;
    display: block;
    max-width: 560px;
    width: 100%;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);

    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    @include media-query($on-laptop) {
      float: none;
      margin: 10px auto;
      border-bottom-right-radius: 6px;
      border-bottom-left-radius: 6px;
    }
  }

  h1 {
    margin-top: 1em;
    color: $color5;
    @include relative-font-size(1.8);
    font-weight: 300;
    em {
      font-style: italic;
    }
  }

  p {
    color: #3a3a3a;
    @include relative-font-size(1.3);
  }

  .get-commercial-support {
    @include relative-font-size(1);
    margin-top: 30px;
    text-align: center;
    a[role=button] {
      @include button();
    }
  }
}

.latest-blog-post {
  padding: 5px;
  border-top: rgba(0, 110, 200, 0.5) dashed 1px;
  border-bottom:  rgba(0, 110, 200, 0.5) dashed 1px;
  background-color: rgba(0, 110, 200, 0.05);

  font-style: italic;

  margin-top: -15px;
  margin-bottom: 20px;
}

.columns > ul {
  @include ul-columns;

  @include media-query($on-palm) {
    display: block;
  }
}

#trusted-by + ul {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  list-style-type: none;

  a {
    display: inline-block;
    color: transparent;
    margin: 15px 30px;
    background-repeat: no-repeat;
    filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.2));
  }

  .logo-gorannet {
    background-image: url(../images/logos/gorannet.png);
    width: 202px;
    height: 37px;
  }
  .logo-utility-warehouse {
    background-image: url(../images/logos/utility-warehouse.png);
    width: 128px;
    height: 74px;
    margin-bottom: 10px;
  }
  .logo-community-fibre {
    background-image: url(../images/logos/community-fibre.png);
    width: 254px;
    height: 63px;
  }
  .logo-alcort {
    background-image: url(../images/logos/alcort.png);
    width: 216px;
    height: 27px;
    }
  .logo-packetfront {
    background-image: url(../images/logos/packetfront.png);
    width: 173px;
    height: 54px;
  }
  .logo-belgian-telecom {
    background-image: url(../images/logos/belgian-telecom.png);
    width: 111px;
    height: 78px;
  }
}
