@mixin button-color($color) {
  $_color1 : lighten($color, 15%);
  $_color2 : lighten($color, 0%);
  color: white;
  text-shadow: 0px 2px 1px adjust-color($color, $lightness: -5%);
  

  background-image: linear-gradient(to bottom, $_color1, $_color2);
  &:hover {
    background-image: linear-gradient(to bottom, darken($_color1, 5%), darken($_color2, 5%));
  }
  &:active {
    color: darken($color, 20%);
    background-image: linear-gradient(to bottom, $_color2, $_color1);
  }
}

// source: http://webdesignerwall.com/tutorials/css3-gradient-buttons
@mixin button {
  position: relative;
  top: 0px;
  vertical-align: baseline;
  margin: 2px;
  outline: none;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  //font-size: $button-font-size;
  //font-family: $font-family;
  padding: 0.8em 2em;
  display: inline-block;
  border-radius: 6px;
  box-shadow: 0 2px 1px rgba(0,0,0,.3);
  @include button-color(#570026);
  border-width: 0;

  &:hover {
    text-decoration: none;
  }
  &:active {
    position: relative;
    top: 1px;
  }
  &.medium {
    font-size: 12px;
  }
  &.small {
    font-size: 11px;
  }
  &.red {
    @include button-color(#dd151f);
  }
}
